import Util = require("Everlaw/Util");

/**
 * Provides functionality for producing and working with Windows.
 */

export function isOpenWindow<W extends Window>(win: W) {
    try {
        return win && !win.closed;
    } catch (e) {
        // IE 11 can throw when accessing win.closed on a non-null object.
    }
    return false;
}

// Attempt to call f on win. If the window is closed (or doesn't exist) or if there is a security
// issue, we return null. Otherwise we return what your function returns.
export function withWindow<W extends Window, T>(win: W, f: (w: W) => T): T {
    if (isOpenWindow(win)) {
        try {
            return f(win);
        } catch (e) {
            // Squash exceptions that are due to permissions issues; rethrow the rest
            if (e.name !== "SecurityError") {
                var msg: string = e.message ? e.message.toLowerCase() : null;
                if (!msg || (msg.indexOf("permission") < 0 && msg.indexOf("security") < 0)) {
                    throw e;
                }
            }
        }
    }
    return null;
}

// Attempt to call f on win.opener. If win or win.opener is closed or inaccessible, f will not be
// called. This method should be used instead of accessing win.opener directly because some browsers
// (e.g., IE 11) can throw an "Access is denied." error when accessing win.opener.
export function withWindowOpener<OW extends Window>(win: Window, f: (w: OW) => void) {
    var opener: OW;
    try {
        opener = <OW>win.opener;
    } catch (e) {
        // If any exceptions occurred, it's because win or win.opener is null or inaccessible. Note
        // that IE 11 can actually throw when accessing win.opener on a non-null object.
        return;
    }
    // Outside the try block because we don't want to catch exceptions that occur in f.
    withWindow(opener, f);
}

// Opens a new window with the given url and parameters, then if successful, executes the callback.
//
// Note that using window.open is NOT sufficiently secure for opening external links because of
// the window.opener reference to the original window. See
// https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c
//
// Note that window.open will typically be blocked by popup blockers when it does not originate from
// a user-generated event. Pseudo-events like Input.tap may originate from timeouts. (Those timeouts
// are themselves triggered by a user event, but browsers don't count that.) Therefore, it's a good
// idea to prefer a simple 'click' event for executing openExternal, even though we usually prefer
// Input.tap over 'click'.
export function openExternal(url: string, name?: string, callback?: () => void) {
    var w = window.open(url, name);
    if (w) {
        // Setting `opener = null` prevents a phishing attack in which this reference to the opener
        // allows the compromised attacker website to manipulate the opener window. Some browsers,
        // however, will not even allow access to w.opener on an external site.
        try {
            w.opener = null;
        } catch (e) {}
        w.focus();
        callback && callback();
    }
    return w;
}

/**
 * Initializes window.name to "{baseName}{tabId}", returning the tabId.
 * If the page was opened with a tab id, or if we already had one (perhaps we navigated away and back),
 * then keep and return it (assuming the baseName matches).
 */
export function initTabId(baseName: string) {
    if (window.name && window.name.slice(0, baseName.length) === baseName) {
        return window.name.slice(baseName.length);
    }
    const tabId = Math.abs(Util.randomInt());
    window.name = baseName + tabId;
    return tabId;
}
